import React from 'react';
import RootElement from "@/components/RootElement"

import "./src/assets/styles/main.scss"
import "./static/fonts/fonts.css"


// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = ({ location }) => {

  // Move to configuration for different environments @todo 
  // if (process.env.NODE_ENV !== 'production') {
  //   return null;
  // }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
  return true;

  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop

    window.scrollTo({
      top: item,
      behavior: "auto",
    })
  }
  return true;
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
